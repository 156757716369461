import { useEffect, useState } from "react";


//import from prime react
import InputCalendar from "../InputCalendar/InputCalendar";


//Dropdown options
const PlaceOptions = [
  {key: "Físico", name: "Físico"},
  {key: "Zoom", name: "Zoom"},
]
const FocusOptions = [
  {key: "Vida", name: "Vida"},
  {key: "GMM", name: "GMM"},
  {key: "Auto", name: "Auto"},
  {key: "Hogar", name: "Hogar"},
  {key: "Mascota", name: "Mascota"},
]
const PorcentageOptions = [
  {key: "25%", name: "25%"},
  {key: "50%", name: "50%"},
  {key: "75%", name: "75%"},
  {key: "100%", name: "100%"},
]
const ProductOptions = [
  {key: "Privilegio", name: "Privilegio"}, // VIDA
  {key: "Platino", name: "Platino"}, // VIDA
  {key: "Dotal", name: "Dotal"}, // VIDA
  {key: "Trasciende", name: "Trasciende"}, // VIDA
  {key: "Vision Plus", name: "Vision Plus"}, // VIDA
  {key: "Profesional", name: "Profesional"}, // VIDA
  {key: "Elige", name: "Elige"}, // VIDA
  {key: "Consolida PPR", name: "Consolida PPR"}, // VIDA
  {key: "Consolida Total", name: "Consolida Total"}, // VIDA
  {key: "Proyecta", name: "Proyecta"}, // VIDA
  {key: "Proyecta Afectó", name: "Proyecta Afectó"}, // VIDA
]
const EstatusOptions = [
  {key: "Prospecto", name: "Prospecto"},
  {key: "Cita", name: "Cita"},
  {key: "Entrevista", name: "Entrevista"},
  {key: "Solicitud", name: "Solicitud"},
  {key: "Pagado", name: "Pagado"},
]


export default function({row, index, handleChangeRow}) {
  const [initRender, setInitRender] = useState(1)

  const [editingPresupuesto, setEditingPresupuesto] = useState(false);
  const [presupuestoValue, setPresupuestoValue] = useState(row.presupuesto);

  const [editingComentarios, setEditingComentarios] = useState(false);
  const [comentariosValue, setComentariosValue] = useState(row.comentarios);

  const [editingSegundaFecha, setEditingSegundaFecha] = useState(false);
  const [SegundaFechaValue, setSegundaFechaValue] = useState(stringToFecha(row.fecha_segunda_cita) ?? '');

  //default states
  const [estatusDelRegistro, setEstatusDelRegistro] = useState(EstatusOptions.find(option => option.name === row.estatus)?.name || "")
  const [lugar, setLugar] = useState(PlaceOptions.find(option => option.name === row.lugar)?.name || "")
  const [enfoque, setEnfoque] = useState(FocusOptions.find(option => option.name === row.enfoque)?.name || "")
  const [porcentaje, setPorcentaje] = useState(PorcentageOptions.find(option => option.name === row.porcentaje_de_cierre)?.name || "")
  const [producto, setProducto] = useState(ProductOptions.find(option => option.name === row.producto)?.name || "")





  const handleComentariosChange = (e) => {
    setComentariosValue(e.target.value);
  };

  const handleComentariosBlur = () => {
    setEditingComentarios(false);
    handleChangeRow(row.uuid, 'comentarios', comentariosValue);
  };

  
  const handlePresupuestoChange = (e) => {
    const rawValue = e.target.value.replace(/[^0-9.]/g, "");
    setPresupuestoValue(rawValue);
  };

  const handlePresupuestoBlur = () => {
    setEditingPresupuesto(false);
    handleChangeRow(row.uuid, 'presupuesto', presupuestoValue);
  };



  const handleSegundaFechaChange = (e) => {
    setSegundaFechaValue(e.value)
    handleChangeRow(row.uuid, 'fecha_segunda_cita', fechaToString(e.value), setEditingSegundaFecha);
  };

  const handleChangeSelect = (e, state) => {

    let value = e.target.value;

    
    if(state == 'estatus') {
      setEstatusDelRegistro(value)
    }
    else if(state == 'lugar') {
      setLugar(value)

    }
    else if(state == 'enfoque') {
      setEnfoque(value)

    }
    else if(state == 'porcentaje_de_cierre') {
      setPorcentaje(value)

    }
    else if(state == 'producto') {
      setProducto(value)

    } 

    handleChangeRow(row.uuid, state, value)
    
    //TODO
    //aqui vamos a agregar el uuid de la fila, junto el parametro que vamos a modificar y su valor
    //y no solamente en los selects cesar, si no en cualquier cambio que se le realice a esta fila okay? eres un crack hermano

  }
  
  const formatCurrency = (value) => {
    if (!value) return ''; // Si el valor es nulo o indefinido, devuelve una cadena vacía
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(Number(value));
  };

  function stringToFecha(fechaString) {
    try{
      if(fechaString) {
        const [dia, mes, año] = fechaString.split('/').map(part => parseInt(part, 10));
        return new Date(año, mes - 1, dia);
      }
    } catch {
      return ''
    }
  }
  function fechaToString(fecha) {
    const fechaObj = new Date(fecha);
    const opciones = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const fechaFormateada = fechaObj.toLocaleDateString('es-ES', opciones);
    return fechaFormateada;
  }







  useEffect(() => {
    if(initRender == 2) {
      // handleChangeRow(row, estatusDelRegistro)
    } else {
      setInitRender(2)
    }
  }, [])



  return (
    <tr>
      <td>
        <select className="estatusSelect" value={estatusDelRegistro} onChange={e => handleChangeSelect(e, 'estatus')}>
          {EstatusOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>


      <td>{row.fecha}</td>
      

      {/* <td>
        <select value={lugar} onChange={e => handleChangeSelect(e, 'lugar')}>
          {PlaceOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td> */}
      

      <td>{row.nombre_cliente}</td>

      <td>
        <select value={enfoque} onChange={e => handleChangeSelect(e, 'enfoque')}>
          {FocusOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>

      <td onClick={() => setEditingSegundaFecha(true)}>
        {
          editingSegundaFecha ? 
          <InputCalendar
            value={SegundaFechaValue} 
            onChange={handleSegundaFechaChange}
          />

          :
          SegundaFechaValue ? fechaToString(SegundaFechaValue) : 'Sin datos'
        }
      </td>



      <td onClick={() => setEditingPresupuesto(true)}>
        {editingPresupuesto ? (
          <input
            type="text"
            value={presupuestoValue}
            onChange={handlePresupuestoChange}
            onBlur={handlePresupuestoBlur}
            autoFocus
          />
        ) : (
          presupuestoValue ? formatCurrency(presupuestoValue) : 'Sin datos'
        )}
      </td>



      <td>
        <select value={porcentaje} onChange={e => handleChangeSelect(e, 'porcentaje_de_cierre')}>
          {PorcentageOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>

      <td>
        <select value={producto} onChange={e => handleChangeSelect(e, 'producto')}>
          {ProductOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>


      <td onClick={() => setEditingComentarios(true)}>
        {editingComentarios ? (
          <textarea
            type="text"
            rows={4}
            cols={100}
            value={comentariosValue}
            onChange={handleComentariosChange}
            onBlur={handleComentariosBlur}
            autoFocus
            style={{resize: "none"}}
          />
        ) : (
          comentariosValue ? comentariosValue : 'Sin comentarios'
        )}
      </td>

      
    </tr>
  )
}